@charset "utf-8";



// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #111;
$background-color: #fdfdfd;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting",
        "bootstrap",
        "custom"
;

.banner {
  background: #1560bd;
}

.banner__content {
  padding: 16px;
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.banner__text {
  flex-grow: 1;
  line-height: 1.4;
  font-family: "Quicksand", sans-serif;
  color: #ffffff;
  text-align: center;
}

.button {
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 12px;
}

.button1 {
  background-color: white;
  color: #1560bd;
  border: 2px solid #1560bd;
}

.button1:hover {
  background-color: #0059b3;
  color: white;
}

.result-box ul{
  border-top: 1px solid #999;
  padding: 15px 10px;
}
.result-box ul li{
  list-style: none;
  border-radius: 3px;
  padding: 15px 10px;
  cursor: pointer;
}
.result-box ul li:hover{
  background: #e9f3ff;
}
