$primaryBlue: #487fff;
$primaryFont: "Ek Mukta", sans-serif;
$shadowColor: #828282;

html,
body {
  font-family: $primaryFont;
}

.navbar-nav {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  padding-bottom: 15px;
}

.navbar-shadow {
  -webkit-box-shadow: 0px 5px 30px -15px $shadowColor;
  -moz-box-shadow:    0px 5px 30px -15px $shadowColor;
  box-shadow:         0px 5px 30px -15px $shadowColor;
  z-index:999;
}

.nav-item-en {
  font-size: 1.25em;
  color: $primaryBlue;
  text-transform: uppercase;

  &:visited {
    color: $primaryBlue;
    text-decoration: none;
  }

  &:focus,
  &:hover {
    color: #333;
    font-weight: 400;
    text-decoration: none;
  }

}

.nav-item {
  text-align: center;
  margin-bottom: 30px;

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
}

.nav-item:last-child {
  margin-bottom: 0;
}

.nav-item-ka {
  font-size: 1em;
  color: #4d4d4d;

  &:visited,
  &:focus,
  &:hover {
    color: #4d4d4d;
    text-decoration: none;
  }

}

.card {
  border: 1px solid #EEE;
  border-radius: 5px;
  -webkit-box-shadow: 0 2px 12px rgba(0,0,0,.1);
  -moz-box-shadow: 0 2px 12px rgba(0,0,0,.1);
  box-shadow: 0 2px 12px rgba(0,0,0,.1);
  padding: 0;
  margin: 10px 10px;
}

.card-small {
  @media screen and (min-width: 1080px) {
    width: 45%;
    height: 300px;
  }
}

.card-image {
  width: 100%;

  @media (min-width: 1080px) {
    height: 180px;
  }
}

.image-rounded {
  border-radius: 5px;
}

.card-content {
  padding: 10px 20px;
}

.card-content-title {
  margin-top: 0.5em;
  font-size: 24px;
  line-height: 1.25em;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  a {
    color: #000;
    text-decoration: none;

    &:hover,
    &:focus {
      color: #000;
    }

  }

}

.card-content-excerpt {
  font-size: 18px;
  margin-top: 10px;
  overflow: hidden;
  text-overflow: ellipsis;

  a {
    color: #888;
    text-decoration: none;

    &:hover,
    &:focus {
      color: #888;
    }

  }

  @media (min-width: 1080px) {
    height: 9.445em;
  }

}

.card-content-details {
  margin-top: 20px;
}

.card-content-category {
  text-transform: uppercase;
  font-weight: 600;
  line-height: 30px;
  vertical-align: middle;
  display: inline-block;

  a {
    color: $primaryBlue;
    text-decoration: none;

    &:visited,
    &:focus,
    &:hover {
      color: $primaryBlue;
      text-decoration: none;
    }

  }

}

.card-content-date {
  color: #666;
  text-align: center;
  display: inline-block;
  line-height: 30px;
  vertical-align: middle;
}

.card-content-more {
  text-align: right;
  display: inline-block;
}

.row-margin {
  margin-top: 50px;
}

.menu-button {
  text-transform: uppercase;
  display: block;
}

.menu-button-en {
  color: $primaryBlue;
}

.menu-button-ka {
  color: #000;
}

.navbar-brand {
  padding: 20px 15px;
  @media (max-width: 1300px) { //changed max-width from 768px to 1300px when press, events and community options were added in the menu.
    padding: 0;
  }
}

.logo-svg {
  @media (max-width: 768px) {
    max-height: 100%;
    margin-top: 15px;
  }
}

.post-custom {
  font-size: 20px;
  font-weight: 300;

  @media (min-width: 768px) {
    width: 72%;
    margin: 100px auto;
  }

}

.more-button {
  display: inline-block;
  background: $primaryBlue;
  color: #fff;
  padding: 5px 10px 5px 10px;
  text-decoration: none;
  border-radius: 5px;

  &:visited,
  &:hover,
  &:focus {
    color: #fff;
    text-decoration: none;
  }

}

.post-content-custom {
  font-weight: 200;
}

.post-content-ka {
  font-size: 22px;
  line-height: 1.6em;
}

::-moz-selection {
  background: $primaryBlue;
  color: #fff;
}

::selection {
  background: $primaryBlue;
  color: #fff;
}

.mobile {
  display: block;
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.desktop {
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }
}
